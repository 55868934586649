<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="home-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img
              class="img-fluid"
              lazy-src="../assets/logo.png"
              src="../assets/logo.png"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="mb105">
        <v-col xs="12" lg="7">
          <div class="front-img">
            <img
              lazy-src="../assets/page-1-img.png"
              src="../assets/page-1-img.png"
            />
          </div>
        </v-col>
        <v-col xs="12" lg="5">
          <div class="front-text clear-both">
            <p>
              Pe lângă inovația medicală și eficiența clară a
              tratamentului<sup>1</sup>, SKYRIZI® are un rol important în
              ”portretizarea” pielii. De aceea, vă propunem să explorați
              metafora pielii, printr-o serie de întrebări, un omagiu oferit
              artelor, științelor și inovației umane.
            </p>
          </div>
          <a
            href="quiz-1"
            style="
              display: block;
              padding: 10px 30px;
              font-size: 2rem;
              background: #2896ce;
              color: #fff;
              text-decoration: none;
              text-align: center;
            "
            >START</a
          >
        </v-col>
      </v-row>
      <v-row class="front-bottom pd0">
        <v-col lg="12">
          <div class="bottom-bg">
            <div class="logo-img-front-bottom" style="height: 45px">
              <img
                class="img-fluid"
                style="float: right"
                lazy-src="../assets/abbvie-logo.png"
                src="../assets/abbvie-logo.png"
              />
            </div>
            <p style="color: #153a6e">
              RO-RISN-200037/Exp. 31.01.2022<br />
              SKYRIZI® este indicat în tratamentul psoriazisului în plăci mod
              erat pâna la sever, la adulţi care sunt candidaţi pentru terapi e
              sistemică. Acest material promoțional este destinat profesion
              iștilor din domeniul sănătătii.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default { name: "Home" };
</script>
<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}
.home-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 400px;
  height: 230px;
}
.front-img {
  margin-left: 95px;
  position: relative;
}
.front-img img {
  max-width: 100%;
  height: auto;
  width: 100%;
  position: absolute;
  top: -92px;
}
.logo-img {
  float: right;
}
.clear-both {
  clear: both;
}
.front-text {
  color: #b1b1b1;
  font-size: 1.3em;
  text-align: right;
  padding: 15px 30px;
}
.mb105 {
  margin-bottom: 105px;
}
.front-bottom {
  margin-left: 109px;
  margin-right: 80px;
  position: relative;

  bottom: 10px;
}
</style>
